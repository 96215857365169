import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga" 
import changePasswordSaga from "./auth/changePassword/saga" 
import ConfirmSaga from "./auth/confirm/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga" 
import dashboardSaga from "./dashboard/saga";
import userAnalyticsSaga from "./userAnalytics/saga";
import vCardSaga from "./vCard/saga";







export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(changePasswordSaga),
    fork(ConfirmSaga),
    fork(ProfileSaga),
    fork(LayoutSaga), 
    fork(dashboardSaga), 
    fork(userAnalyticsSaga), 
    fork(vCardSaga), 
    
 
  
 
   










  ])
}
