import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { GET_USER_ANALYTICS,ADD_PROFILE_VISIT,ADD_INCREASE} from "./actionTypes";
import {
  
   getUserAnalyticsSuccess,getUserAnalyticsFail,
   addIncreaseSuccess,addIncreaseFail,
   addProfileVisitSuccess,addProfileVisitFail
  } from "./actions";

//Include Both Helper File with needed methods 
import {
  
    getUserByIdAnalytics,
    addProfileVisit,
    addIncrease,
   
  } from "../../helpers/fakebackend_helper"
  
  
function* fetchUserAnalytics({ payload: user}) {
  try {
      const response = yield call(getUserByIdAnalytics,user)
      yield put(getUserAnalyticsSuccess(response));
  } catch (error) {
      yield put(getUserAnalyticsFail(error));
  }
}
function* onAddProfileVisit({ payload: link}) {
  try {
    const response = yield call(addProfileVisit, link)
    yield put(addProfileVisitSuccess(response))
  } catch (error) {
    yield put(addProfileVisitFail(error))
  }
}
function* onAddIncrease({ payload: link}) {
  try {
    const response = yield call(addIncrease, link)
    yield put(addIncreaseSuccess(response))
  } catch (error) {
    yield put(addIncreaseFail(error))
  }
}
 

export function* userAnalyticsSaga() {
  
    yield takeEvery(GET_USER_ANALYTICS, fetchUserAnalytics)
    yield takeEvery(ADD_PROFILE_VISIT, onAddProfileVisit)
    yield takeEvery(ADD_INCREASE, onAddIncrease)
}

export default userAnalyticsSaga;
