import {
  
    GET_USER_ANALYTICS, 
    GET_USER_ANALYTICS_SUCCESS,
    GET_USER_ANALYTICS_FAIL,
    ADD_PROFILE_VISIT, 
    ADD_PROFILE_VISIT_SUCCESS, 
    ADD_PROFILE_VISIT_FAIL, 
    ADD_INCREASE, 
    ADD_INCREASE_SUCCESS, 
    ADD_INCREASE_FAIL,
   

} from "./actionTypes"; 

export const getUserAnalytics = (user) => ({
    type: GET_USER_ANALYTICS, 
    payload:user
}); 
export const getUserAnalyticsSuccess = (data) => ({
    type: GET_USER_ANALYTICS_SUCCESS,
    payload: data
});
export const getUserAnalyticsFail = (error) => ({
    type: GET_USER_ANALYTICS_FAIL, 
    payload: error,
});
export const addProfileVisit = (link) => ({
    type: ADD_PROFILE_VISIT,
    payload: link
});

export const addProfileVisitSuccess = (data) => ({
    type: ADD_PROFILE_VISIT_SUCCESS,
    payload: data
});
 
export const addProfileVisitFail = (error) => ({
    type: ADD_PROFILE_VISIT_FAIL,
    payload: error,
});

export const addIncrease = (link) => ({
    type: ADD_INCREASE,
    payload: link
});
 
export const addIncreaseSuccess = (data) => ({
    type: ADD_INCREASE_SUCCESS,
    payload: data
});

export const addIncreaseFail = (error) => ({
    type: ADD_INCREASE_FAIL,
    payload: error,
});