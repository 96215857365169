import {

    GET_USER_ANALYTICS_SUCCESS,
    GET_USER_ANALYTICS_FAIL,
    ADD_PROFILE_VISIT_SUCCESS, 
    ADD_PROFILE_VISIT_FAIL, 
    ADD_INCREASE_SUCCESS, 
    ADD_INCREASE_FAIL,
} from "./actionTypes";



const INIT_STATE = { 
    chartsData: [], 
     
    error: null,
    data: [], 
    user:[],
    socialLink:[],
    profileUpdateStatus: false, 
    message:null
};

const UserAnalytics = (state = INIT_STATE, action) => {
    switch (action.type) {
      
        case GET_USER_ANALYTICS_SUCCESS:
    
            return {
                ...state,
                user: action.payload.data
            }
            case ADD_PROFILE_VISIT_SUCCESS:
                return {
                    ...state,
                    user:action.payload.data
                }
            case ADD_INCREASE_SUCCESS:
            case ADD_PROFILE_VISIT_FAIL:
            case ADD_INCREASE_FAIL:
                 return {
                     ...state,
                     user:action.payload.data 
                 }
        
       
           
                 //FAILS
       
        case GET_USER_ANALYTICS_FAIL:
                return {
                    ...state,
                    error: action.payload
                }
    
       
        default:
            return state;
    }
}


export default UserAnalytics;