import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import {
  Row, Col, Collapse, Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import classname from "classnames"

//i18n
import { withTranslation } from "react-i18next"

import { connect } from "react-redux"
import { authorize, Role } from "./../helpers/authorize"

const Navbar = props => {

  const [dashboard, setdashboard] = useState(false)
  const [adminPanel, setadminPanel] = useState(false)


 
 


  let user = JSON.parse(localStorage.getItem("authUser"))

  return (
    <React.Fragment>
     

     
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)
