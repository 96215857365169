import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_VCARDS, DELETE_VCARD, ADD_VCARD, UPDATE_VCARD,GET_VCARDS_MY_VCARDS,GET_VCARD_DETAIL} from "./actionTypes";
import {
    getVcardsSuccess, getVcardsFail,
    deleteVcardSuccess, deleteVcardFail,
    updateVcardSuccess, updateVcardFail,
    addVcardSuccess, addVcardFail,
    detailVcardSuccess,detailVcardFail
 } from "./actions"; 
 
//Include Both Helper File with needed methods
import {
    getVcards, //API CONNECTION
    delVcard,
    addVcard,
    updateVcard,
    getVcardsDetail
}
    from "../../helpers/fakebackend_helper"; 


    function* VcardDetail({payload:id}) {
        try {
            const response = yield call(getVcardsDetail, id)
            yield put(detailVcardSuccess(response));
        } catch (error) {
            yield put(detailVcardFail(error));
        }
    }
function* fetchVcards() {
    try {
        const response = yield call(getVcards)
        yield put(getVcardsSuccess(response));
    } catch (error) {
        yield put(getVcardsFail(error));
    }
}
function* onDeleteVcards({payload:id}){
    try{
        const response = yield call(delVcard, id)
        yield put(deleteVcardSuccess(response));
    } catch (error) {
        yield put(deleteVcardFail(error));
    }
}

function* onAddVcard({payload:vcard}){
    try{
        const response = yield call(addVcard, vcard)
        yield put(addVcardSuccess(response));
    } catch (error) {
        yield put(addVcardFail(error));
    }
}

function* onUpdateVcard({payload:vcard}){
    try{
        const response = yield call(updateVcard, vcard)
        yield put(updateVcardSuccess(response));
    } catch (error) {
        yield put(updateVcardFail(error));
    }
}

export function* vcardsSaga() {
    yield takeEvery(GET_VCARDS, fetchVcards);
    yield takeEvery(DELETE_VCARD, onDeleteVcards);
    yield takeEvery(ADD_VCARD, onAddVcard);
    yield takeEvery(UPDATE_VCARD, onUpdateVcard);
    yield takeEvery(GET_VCARD_DETAIL, VcardDetail);
    
    
}

export default vcardsSaga;
