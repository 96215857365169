import {
    API_SUCCESS,
    API_FAIL, 
    GET_CHARTS_DATA,
    UPDATE_PROFILE,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAIL,
    UPDATE_PROFILE_PHOTO,
    UPDATE_PROFILE_PHOTO_SUCCESS,
    UPDATE_PROFILE_PHOTO_FAIL,
    UPDATE_BACKGROUND_PHOTO,
    UPDATE_BACKGROUND_PHOTO_SUCCESS,
    UPDATE_BACKGROUND_PHOTO_FAIL,
    UPDATE_THEME,
    UPDATE_THEME_SUCCESS,
    UPDATE_THEME_FAIL,
    GET_LIST,
    GET_LIST_SUCCESS, 
    GET_LIST_FAIL,
    ADD_LINK,  
    ADD_LINK_SUCCESS,  
    ADD_LINK_FAIL,
    ADD_PROFILE_INFO_SUCCESS, 
    ADD_PROFILE_INFO_FAIL,
    ADD_PROFILE_INFO, 
    ADD_STYLE,
    ADD_STYLE_SUCCESS, 
    ADD_STYLE_FAIL,
    ADD_SOCIAL_LINK, 
    ADD_SOCIAL_LINK_SUCCESS, 
    ADD_SOCIAL_LINK_FAIL,
    GET_USER, 
    GET_USER_SUCCESS, 
    GET_USER_FAIL,
    GET_PARENT_DATA, 
    GET_PARENT_DATA_SUCCESS, 
    GET_PARENT_DATA_FAIL, 
 
    GET_BY_USERNAME, 
    GET_BY_USERNAME_SUCCESS,
    GET_BY_USERNAME_FAIL,
    RESET_DASHBOARD ,
    CHECK_USER_NAME,
    CHECK_USER_NAME_SUCCESS, 
    CHECK_USER_NAME_FAIL,
    ADD_PROFILE, 
    ADD_PROFILE_SUCCESS,  
    ADD_PROFILE_FAIL,  
    GET_PROFILES,
    GET_PROFILES_SUCCESS, 
    GET_PROFILES_FAIL,
    ADD_GUEST,
    ADD_GUEST_SUCCESS,
    ADD_GUEST_FAIL,
    UPDATE_GUEST, 
    UPDATE_GUEST_SUCCESS,
    UPDATE_GUEST_FAIL,
    GET_CORPORATE_LIST,
    GET_CORPORATE_LIST_SUCCESS,  
    GET_CORPORATE_LIST_FAIL,
    DELETE_GUEST,
    DELETE_GUEST_SUCCESS,
    DELETE_GUEST_FAIL,
    COMBINE_PROFILE,
    COMBINE_PROFILE_SUCCESS, 
    COMBINE_PROFILE_FAIL,
    COMBINE,
    COMBINE_SUCCESS, 
    COMBINE_FAIL, 
    UPDATE_PLAN_ACTIVE, 
    UPDATE_PLAN_ACTIVE_SUCCESS, 
    UPDATE_PLAN_ACTIVE_FAIL,  
 
} from "./actionTypes"; 
export const resetDashboard = () => ({
    type: RESET_DASHBOARD,
});
export const updatePlanActive = (plan) => ({
    type: UPDATE_PLAN_ACTIVE, 
    payload: plan, 
     
});

export const updatePlanActiveSuccess = (data) => ({
    type: UPDATE_PLAN_ACTIVE_SUCCESS,
    payload: data
});

export const updatePlanActiveFail = (error) => ({
    type: UPDATE_PLAN_ACTIVE_FAIL,
    payload: error, 
});
export const combine = (email) => ({
    type:  COMBINE,
    payload: email
});

export const combineSuccess = (data) => ({  
    type: COMBINE_SUCCESS,
    payload: data
});

export const combineFail = (error) => ({
    type: COMBINE_FAIL,
    payload: error,
});
export const combineProfile = (email) => ({
    type:  COMBINE_PROFILE,
    payload: email
});

export const combineProfileSuccess = (data) => ({
    type: COMBINE_PROFILE_SUCCESS,
    payload: data
});

export const combineProfileFail = (error) => ({
    type: COMBINE_PROFILE_FAIL,
    payload: error,
});
export const deleteGuest = (id) => ({
    type:  DELETE_GUEST,
    payload: id
});

export const deleteGuestSuccess = (data) => ({
    type: DELETE_GUEST_SUCCESS,
    payload: data
});

export const deleteGuestFail = (error) => ({
    type: DELETE_GUEST_FAIL,
    payload: error,
});
export const getParentData = (user) => ({
    type: GET_PARENT_DATA, 
    payload:user
}); 
export const getParentDataSuccess = (data) => ({
    type: GET_PARENT_DATA_SUCCESS,
    payload: data
});
export const getParentDataFail = (error) => ({
    type: GET_PARENT_DATA_FAIL, 
    payload: error, 
});
export const getCorporateList = (user) => ({
    type: GET_CORPORATE_LIST, 
    payload:user 
});  
export const getCorporateListSuccess = (data) => ({
    type: GET_CORPORATE_LIST_SUCCESS,
    payload: data
});
export const getCorporateListFail = (error) => ({ 
    type: GET_CORPORATE_LIST_FAIL, 
    payload: error,
});
export const updateGuest = (guest,file) => ({
    type: UPDATE_GUEST,
    payload: guest,
    file: file 
}); 

export const updateGuestSuccess = (data) => ({
    type: UPDATE_GUEST_SUCCESS,
    payload: data
});

export const updateGuestFail = (error) => ({
    type: UPDATE_GUEST_FAIL,
    payload: error,
});
export const addGuest = (guest,file) => ({
    type: ADD_GUEST,
    payload: guest,
    file: file 
});  

export const addGuestSuccess = (data) => ({
    type: ADD_GUEST_SUCCESS,
    payload: data
});

export const addGuestFail = (error) => ({
    type: ADD_GUEST_FAIL,
    payload: error,
});
export const getProfiles = (mail) => ({
    type: GET_PROFILES,
    payload: mail  
}); 

export const getProfilesSuccess = (data) => ({
    type: GET_PROFILES_SUCCESS,
    payload: data
});

export const getProfilesFail = (error) => ({
    type: GET_PROFILES_FAIL,
    payload: error,
});
export const addProfile = (profile) => ({
    type: ADD_PROFILE,
    payload: profile  
}); 

export const addProfileSuccess = (data) => ({
    type: ADD_PROFILE_SUCCESS,
    payload: data
});

export const addProfileFail = (error) => ({
    type: ADD_PROFILE_FAIL,
    payload: error,
});
export const apiSuccess = (actionType, data) => ({
    type: API_SUCCESS,
    payload: { actionType, data },
});

export const apiFail = (actionType, error) => ({
    type: API_FAIL,
    payload: { actionType, error },
});
 
// charts data
export const getChartsData = (periodType) => ({
    type: GET_CHARTS_DATA,
    payload: periodType
});
export const updateTheme = (companyId) => ({
   
    type: UPDATE_THEME,
    payload: companyId,
    
});   
 
export const updateThemeSuccess = (data) => ({
    type: UPDATE_THEME_SUCCESS,
    payload: data
});

export const updateThemeFail = (error) => ({
    type: UPDATE_THEME_FAIL,
    payload: error,
});
export const updateBackgroundPhoto = (companyId, file) => ({
   
    type: UPDATE_BACKGROUND_PHOTO, 
    payload: companyId,
    file: file 
});   

export const updateBackgroundPhotoSuccess = (data) => ({
    type: UPDATE_BACKGROUND_PHOTO_SUCCESS,
    payload: data
});

export const updateBackgroundPhotoFail = (error) => ({
    type: UPDATE_BACKGROUND_PHOTO_FAIL,
    payload: error,
});
export const updateProfilePhoto = (companyId, file) => ({
   
    type: UPDATE_PROFILE_PHOTO,
    payload: companyId,
    file: file 
});   

export const updateProfilePhotoSuccess = (data) => ({
    type: UPDATE_PROFILE_PHOTO_SUCCESS,
    payload: data
});

export const updateProfilePhotoFail = (error) => ({
    type: UPDATE_PROFILE_PHOTO_FAIL,
    payload: error,
});
export const updateProfile = (companyId, file) => ({
   
    type: UPDATE_PROFILE,
    payload: companyId,
    file: file 
});   

export const updateProfileSuccess = (data) => ({
    type: UPDATE_PROFILE_SUCCESS,
    payload: data
});

export const updateProfileFail = (error) => ({
    type: UPDATE_PROFILE_FAIL,
    payload: error,
});
export const getList = (user) => ({
    type: GET_LIST, 
    payload:user
}); 
export const getListSuccess = (data) => ({
    type: GET_LIST_SUCCESS,
    payload: data
});
export const getListFail = (error) => ({ 
    type: GET_LIST_FAIL, 
    payload: error,
});
export const getByUsername = (user) => ({
    type: GET_BY_USERNAME, 
    payload:user
}); 
export const getByUsernameSuccess = (data) => ({
    type: GET_BY_USERNAME_SUCCESS,
    payload: data
});
export const getByUsernameFail = (error) => ({
    type: GET_BY_USERNAME_FAIL, 
    payload: error,
});

export const getUser = (user) => ({
    type: GET_USER, 
    payload:user
}); 
export const getUserSuccess = (data) => ({
    type: GET_USER_SUCCESS,
    payload: data
});
export const getUserFail = (error) => ({
    type: GET_USER_FAIL, 
    payload: error,
});
export const addLink = (link, file) => ({
    type: ADD_LINK,
    payload: link,
    file: file
});

export const addLinkSuccess = (data) => ({
    type: ADD_LINK_SUCCESS,
    payload: data
});

export const addLinkFail = (error) => ({
    type: ADD_LINK_FAIL,
    payload: error,
});
export const addProfileInfo = (link) => ({
    type: ADD_PROFILE_INFO,
    payload: link
});

export const addProfileInfoSuccess = (data) => ({
    type: ADD_PROFILE_INFO_SUCCESS,
    payload: data
});

export const addProfileInfoFail = (error) => ({
    type: ADD_PROFILE_INFO_FAIL,
    payload: error,
});
export const addStyle = (link) => ({
    type: ADD_STYLE,
    payload: link
});

export const addStyleSuccess = (data) => ({
    type: ADD_STYLE_SUCCESS,
    payload: data
});

export const addStyleFail = (error) => ({
    type: ADD_STYLE_FAIL,
    payload: error,
});
export const addSocialLink = (link) => ({
    type: ADD_SOCIAL_LINK,
    payload: link
});

export const addSocialLinkSuccess = (data) => ({
    type: ADD_SOCIAL_LINK_SUCCESS,
    payload: data
});

export const addSocialLinkFail = (error) => ({
    type: ADD_SOCIAL_LINK_FAIL,
    payload: error,
});
export const checkUserName = (link) => ({
    type: CHECK_USER_NAME,
    payload: link 
});

export const checkUserNameSuccess = (data) => ({
    type: CHECK_USER_NAME_SUCCESS,
    payload: data
});

export const checkUserNameFail = (error) => ({
    type: CHECK_USER_NAME_FAIL,
    payload: error,
});